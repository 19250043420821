import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { addNewTeamUser } from '../services';
import { useToast } from '../hooks/useToast';
import { useHistory, useParams } from 'react-router';
import { AddAdminUserTemplate } from '../components/organisms/CommonTemplate/UserManagement/AddAdminUserTemplate';

const AddAdminUser = () => {
  const history = useHistory();
  const id = useParams<{ id: string }>();
  const [showToast, setShowToast] = useToast(false);
  const [errMsg, setErrMsg] = useState<string>('Something went wrong');

  const { mutate, isLoading: isMutating } = useMutation(addNewTeamUser, {
    onSuccess: () => {
      history.push(`/${id.id}/user-management`);
    },
    onError: (e: any) => {
      setErrMsg(e?.response?.data?.message || 'Something went wrong');
      setShowToast(true);
    },
  });

  const handleFormSubmit = (values: any) => {
    mutate({ ...values, teamId: id.id });
  };

  return (
    <AddAdminUserTemplate
      isMutating={isMutating}
      showToast={showToast}
      setShowToast={setShowToast}
      errMsg={errMsg}
      handleSubmit={handleFormSubmit}
    />
  );
};

export default AddAdminUser;
