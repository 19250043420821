import React from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';

import { addTeamNotification } from '../services';
import { AddAdminNotificationTemplate } from '../components/organisms/CommonTemplate/Notification/AddAdminNotificationTemplate';

const TeamAddNotification = () => {
  const history = useHistory();

  const { mutate, isLoading: isMutating } = useMutation(addTeamNotification, {
    onSuccess: async () => {
      history.push({
        pathname: `/notification`,
        search: '?list=beforePublishing',
      });
    },
    onError: (e: any) => {
      console.log(e);
    },
  });

  return <AddAdminNotificationTemplate mutate={mutate} isMutating={isMutating} />;
};

export default TeamAddNotification;
