import React, { useState } from 'react';
import moment from 'moment';
import { useQuery, useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router';

import { useToast } from '../hooks/useToast';
import { convertToNDigits, extractCoinBalance } from '../utils';
import { editMemberManagement, fetchSingleMemberDataTeam } from '../services';
import { EditMemberManagementTemplate } from '../components/organisms/CommonTemplate/MemberManagement/EditMemberManagementTemplate';
import { fetchUserGift, fetchUserGiftList, getUserGiftList } from '../services/gift';

function TeamEditMemberManagement() {
  const history = useHistory();
  const { pathname } = useLocation();
  const id = pathname.split('/')[3];

  const [showToast, setShowToast] = useToast(false);
  const [uniformId, setUniformID] = useState<string>('');
  const [formDetails, setFormDetails] = useState<any>({
    shirtSize: '',
    uniformSize: '',
    uniformNo: '',
    uniformName: '',
  });
  const [tshirtId, setTshirtID] = useState<string>('');
  const teamId = '6ae89d3c-0a33-40a0-abdb-c5f01e6e9d4d';

  const { data: fetchedData, isLoading }: { data: any; isLoading: boolean } = useQuery(
    ['fetchSingleUserMember', id],
    fetchSingleMemberDataTeam,
    {
      enabled: !!id,
      select: ({ data }: any) => {
        const birthday = data?.birthday ? data?.birthday.split('/') : '';
        const coin_balance = extractCoinBalance(data?.totalTeamCoin);

        return {
          ...data,
          membership_id: convertToNDigits(data?.membershipStatus?.items[0]?.number),
          coin_balance,
          year: birthday[0],
          month: birthday[1],
          day: birthday[2],
          ext_phone: data?.phone !== null && data?.phone.substring(0, 3),
          first_phone: data?.phone !== null && data?.phone.substring(3, 7),
          second_phone: data?.phone !== null && data?.phone.substring(7),
          purchasedDate: data?.membershipStatus?.items[data?.membershipStatus?.items?.length - 1]
            ?.purchaseDate
            ? moment(
                data?.membershipStatus?.items[data?.membershipStatus?.items?.length - 1]
                  ?.purchaseDate
              ).format('YYYY/MM/DD')
            : '',
          expiryDate: data?.membershipStatus?.items[data?.membershipStatus?.items?.length - 1]
            ?.exDate
            ? moment(
                data?.membershipStatus?.items[data?.membershipStatus?.items?.length - 1]?.exDate
              ).format('YYYY/MM/DD')
            : '',
          lastQRScanned: data?.lastQrRead ? moment(data?.lastQrRead).format('YYYY/MM/DD') : '',
          uniformNo: '',
        };
      },
    }
  );

  const enableCondition =
    fetchedData &&
    fetchedData.statusNameEnglish !== 'RED' &&
    fetchedData.statusNameEnglish !== 'SILVER';

  const { mutate, isLoading: isMutating } = useMutation(editMemberManagement, {
    onSuccess: async () => {
      history.push({
        pathname: `/member-management`,
      });
    },
    onError: () => {
      setShowToast(true);
    },
  });

  const {
    data: userGiftData,
    isLoading: loadingUserGiftList,
    refetch,
  } = useQuery(['getUserGiftList', id, teamId], fetchUserGiftList, {
    enabled: enableCondition && fetchedData ? true : false,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
  });

  const { data: masterId } = useQuery(['fetchGiftData', teamId], getUserGiftList, {
    enabled: id && enableCondition && userGiftData?.data?.gifts?.length === 0 ? true : false,
    select(res) {
      const result: { uniformMasterId?: string; tshirtMasterId?: string } = {};
      res.data.gifts.map((items: any) => {
        if (items.name == 'ユニフォーム') {
          result.uniformMasterId = items.id;
        } else if (items.name === 'Tシャツ') {
          result.tshirtMasterId = items.id;
        }
      });
      return result;
    },
  });

  const { isLoading: loadingGiftSize } = useQuery(
    ['userGiftSize', userGiftData?.data?.gifts![0]?.id],
    fetchUserGift,
    {
      enabled: enableCondition && userGiftData?.data?.gifts![0]?.id !== undefined ? true : false,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      onSuccess(res) {
        if (
          fetchedData.statusNameEnglish !== 'RED' &&
          fetchedData.statusNameEnglish !== 'SILVER' &&
          fetchedData.statusNameEnglish !== 'GOLD'
        ) {
          if (res?.data?.choice?.includes('number')) {
            setUniformID(res?.data?.id);
            setFormDetails((prevState: any) => {
              return {
                ...prevState,
                uniformSize: res?.data?.choice ? JSON.parse(res?.data?.choice)?.size : '-',
                uniformNo: res?.data?.choice ? JSON.parse(res?.data?.choice)?.number : '-',
                uniformName: res?.data?.choice ? JSON.parse(res?.data?.choice)?.name : '-',
              };
            });
          } else {
            setTshirtID(res?.data?.id);
            setFormDetails((prevState: any) => {
              return {
                ...prevState,
                shirtSize: res?.data?.choice ? JSON.parse(res?.data?.choice)?.size : '-',
              };
            });
          }
        } else if (fetchedData.statusNameEnglish === 'GOLD') {
          setTshirtID(res?.data?.id);
          setFormDetails((prevState: any) => {
            return {
              ...prevState,
              shirtSize: res?.data?.choice ? JSON.parse(res?.data?.choice)?.size : '-',
            };
          });
        }
      },
    }
  );

  const { isLoading: loadingGiftForm } = useQuery(
    [
      'userGiftForm',
      userGiftData?.data?.gifts![1]?.id !== undefined ? userGiftData?.data?.gifts![1].id : 0,
    ],
    fetchUserGift,
    {
      enabled: enableCondition && userGiftData?.data?.gifts![1]?.id !== undefined ? true : false,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      onSuccess(res) {
        if (
          fetchedData.statusNameEnglish !== 'RED' &&
          fetchedData.statusNameEnglish !== 'SILVER' &&
          fetchedData.statusNameEnglish !== 'GOLD'
        ) {
          if (res?.data?.choice?.includes('number')) {
            setUniformID(res?.data?.id);
            setFormDetails((prevState: any) => {
              return {
                ...prevState,
                uniformSize: res?.data?.choice ? JSON.parse(res?.data?.choice)?.size : '-',
                uniformNo: res?.data?.choice ? JSON.parse(res?.data?.choice)?.number : '-',
                uniformName: res?.data?.choice ? JSON.parse(res?.data?.choice)?.name : '-',
              };
            });
          } else {
            setTshirtID(res?.data?.id);
            setFormDetails((prevState: any) => {
              return {
                ...prevState,
                shirtSize: res?.data?.choice ? JSON.parse(res?.data?.choice)?.size : '-',
              };
            });
          }
        } else if (fetchedData.statusNameEnglish === 'GOLD') {
          setTshirtID(res?.data?.id);
          setFormDetails((prevState: any) => {
            return {
              ...prevState,
              shirtSize: res?.data?.choice ? JSON.parse(res?.data?.choice)?.size : '-',
            };
          });
        }
      },
    }
  );

  return (
    <EditMemberManagementTemplate
      fetchedData={fetchedData}
      showToast={showToast}
      setShowToast={setShowToast}
      isLoading={isLoading || loadingGiftForm || loadingGiftSize || loadingUserGiftList}
      isMutating={isMutating}
      mutate={mutate}
      formDetails={formDetails}
      userGiftData={userGiftData}
      masterId={masterId}
      tshirtId={tshirtId}
      userId={id}
      uniformId={uniformId}
      refetch={refetch}
    />
  );
}

export default TeamEditMemberManagement;
