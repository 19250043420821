import styled from 'styled-components';
import { IonLabel } from '@ionic/react';
import { getDate } from '../../../utils';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef } from 'react';
import { TextField, RadioButton, Checkbox, Button } from '../../atoms';

const Form = styled.form`
  .default-input {
    font-size: 13px;
    display: block;
    width: 98%;
    border: 1px solid #d9d9d9 !important;
    border-radius: 5px;
    height: 40px;
    margin: 10px 0px 2px;
    padding: 0 10px;
    outline: none;

    @media (min-width: 1550px) {
      width: 98.5%;
    }

    @media (min-width: 1850px) {
      width: 98.8%;
    }
  }
  .textfield {
    margin-bottom: 20px;
  }
  .info {
    color: #b80629;
    margin-left: 15px;
    margin-bottom: 0px;
  }
  .message {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .input {
    margin-bottom: 20px;
  }
  .disabled {
    input {
      background-color: #ebe9e9;
    }
  }

  ion-radio {
    --color-checked: #b80629;
  }

  .right-field {
    margin-left: 20px;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .date-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .delivery-address {
    background-color: #f9f8f8;
    padding: 20px;
    margin-bottom: 20px;
  }

  .phone-section {
    display: flex;

    .input {
      width: 202px;
    }

    span {
      margin: 40px 10px 0px;
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;

    .btn {
      width: 245px;
    }
  }
`;

interface Props {
  formik: any;
  isMutating?: boolean;
  statusName?: string;
}

const TeamMemberManagementForm: React.FC<Props> = ({ formik, isMutating, statusName }) => {
  const [isChecked, setIsChecked] = useState(false);

  const textField1 = useRef<any>(null);
  const textField2 = useRef<any>(null);
  const textField3 = useRef<any>(null);

  const sizeOptions = [
    { label: '150', value: '150' },
    { label: 'S', value: 'S' },
    { label: 'M', value: 'M' },
    { label: 'L', value: 'L' },
    { label: 'O(XL)', value: 'O' },
    { label: 'XO(2XL)', value: 'XO' },
    { label: '2XO(3XL)', value: '2XO' },
    { label: '3XO(4XL)', value: '3XO' },
  ];
  const { t } = useTranslation();

  const phoneChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'ext_phone' && e.target.value.length === 3) {
      textField2.current.setFocus();
    } else if (e.target.name === 'first_phone') {
      if (e.target.value.length === 4) {
        textField3.current.setFocus();
      } else if (e.target.value.length === 0) {
        textField1.current.setFocus();
      }
    } else if (e.target.name === 'second_phone' && e.target.value.length === 0) {
      textField2.current.setFocus();
    }
  };

  const dateChange = (name: string, value: string) => {
    formik.setFieldValue(name, value);
  };

  useEffect(() => {
    if (formik?.values) {
      if (isChecked) {
        formik.setFieldValue('mailAddress', formik?.values?.address);
        formik.setFieldValue('mailAddress1', formik?.values?.address1);
        formik.setFieldValue('mailPostcode', formik?.values?.postcode);
      } else {
        formik.setFieldValue('mailAddress', formik?.values?.mailAddress);
        formik.setFieldValue('mailAddress1', formik?.values?.mailAddress1);
        formik.setFieldValue('mailPostcode', formik?.values?.mailPostcode);
      }
    }
  }, [isChecked, formik?.values]);

  const renderTshirtSizeField = () => {
    if (statusName !== 'RED' && statusName !== 'SILVER') {
      return (
        <>
          <TextField
            className="textfield"
            name="shirtSize"
            label="T-shirt size"
            options={sizeOptions}
            type="select"
            value={formik?.values?.shirtSize}
            onChange={(e: any) => {
              formik.setFieldValue('shirtSize', e?.value);
            }}
            error={formik.touched.shirtSize && formik.errors.shirtSize}
          />
        </>
      );
    }
  };

  const renderOtherField = () => {
    if (statusName !== 'RED' && statusName !== 'SILVER' && statusName !== 'GOLD') {
      return (
        <>
          <TextField
            name="uniformSize"
            label="Uniform size"
            className="input"
            type="select"
            options={sizeOptions}
            value={formik?.values?.uniformSize}
            onChange={(e: any) => {
              formik.setFieldValue('uniformSize', e?.value);
            }}
            error={formik?.touched?.uniformSize && formik?.errors?.uniformSize}
          />
          <div className="message">
            <IonLabel>
              {t('Uniform no')}
              <span className="info">{t('*Enter numbers from 0-99 & 123.')}</span>
            </IonLabel>
          </div>
          <div className="textfield">
            <input
              name="uniformNo"
              className="default-input"
              maxLength={3}
              value={formik?.values?.uniformNo}
              onChange={(e) => {
                const inputValue = e?.target?.value;
                if (/^(\d{0,2}|123)$/.test(inputValue)) {
                  formik.setFieldValue('uniformNo', inputValue);
                }
              }}
            />
          </div>
          <div className="message">
            <IonLabel>
              {t('Uniform name')}
              <span className="info">
                {t('*Capital alphabets only within 15 chars , Allow space')}
              </span>
            </IonLabel>
          </div>
          <div className="textfield">
            <input
              name="uniformName"
              className="default-input"
              value={formik?.values?.uniformName}
              onChange={(e: any) => {
                if (e?.target?.value?.length <= 15) {
                  formik.setFieldValue('uniformName', e?.target?.value.replace(/[^A-Z\s]/g, ''));
                }
              }}
              onKeyDown={(e: any) => {
                if (!/[A-Z ]$/i.test(e.key)) e.preventDefault();
              }}
            />
          </div>
        </>
      );
    }
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="grid">
        <TextField
          disabled
          name="membership_id"
          label="Membership ID"
          className="input disabled"
          value={formik?.values?.membership_id}
          onChange={formik.handleChange}
          error={formik.touched.membership_id && formik.errors.membership_id}
        />
        <TextField
          disabled
          name="membership_status"
          label="Membership Status"
          className="right-field input disabled"
          value={formik?.values?.statusNameEnglish}
          onChange={formik.handleChange}
          error={formik.touched.membership_status && formik.errors.membership_status}
        />
      </div>
      <div className="grid">
        <TextField
          disabled
          name="purchasedDate"
          label="Purchased Date"
          className="input disabled"
          value={formik?.values?.purchasedDate}
          onChange={formik.handleChange}
          error={formik.touched.purchasedDate && formik.errors.purchasedDate}
        />
        <TextField
          disabled
          name="expiryDate"
          label="Expiry Date"
          className="right-field input disabled"
          value={formik?.values?.expiryDate}
          onChange={formik.handleChange}
          error={formik.touched.expiryDate && formik.errors.expiryDate}
        />
      </div>
      <div className="grid">
        <TextField
          count
          disabled
          name="name_sei"
          maxlength={100}
          label="Last name"
          className="input disabled"
          value={formik?.values?.name_sei}
          onChange={formik.handleChange}
          error={formik.touched.name_sei && formik.errors.name_sei}
        />
        <TextField
          count
          disabled
          maxlength={100}
          name="name_mei"
          label="First name"
          className="right-field input disabled"
          value={formik?.values?.name_mei}
          onChange={formik.handleChange}
          error={formik.touched.name_mei && formik.errors.name_mei}
        />
      </div>
      <div className="grid">
        <TextField
          count
          disabled
          maxlength={100}
          name="furigana_sei"
          label="Last Furigana"
          className="input disabled"
          value={formik?.values?.furigana_sei}
          onChange={formik.handleChange}
          error={formik.touched.furigana_sei && formik.errors.furigana_sei}
        />
        <TextField
          count
          disabled
          maxlength={100}
          name="furigana_mei"
          label="First Furigana"
          className="right-field input disabled"
          value={formik?.values?.furigana_mei}
          onChange={formik.handleChange}
          error={formik.touched.furigana_mei && formik.errors.furigana_mei}
        />
      </div>
      <TextField
        disabled
        name="email"
        label="Email address"
        className="input disabled"
        value={formik?.values?.email}
        onChange={formik.handleChange}
        error={formik.touched.email && formik.errors.email}
      />
      <div className="grid">
        <TextField
          disabled
          name="coin_balance"
          label="Coin Balance"
          className="input disabled"
          value={formik?.values?.coin_balance}
          onChange={formik.handleChange}
          error={formik.touched.coin_balance && formik.errors.coin_balance}
        />
        <TextField
          disabled
          name="lastQRScanned"
          label="Last QR code scanned on"
          className="right-field input disabled"
          value={formik?.values?.lastQRScanned}
          onChange={formik.handleChange}
          error={formik.touched.lastQRScanned && formik.errors.lastQRScanned}
        />
      </div>
      <RadioButton
        name="isTestUser"
        label="Test member"
        options={[
          { value: true, label: 'Yes' },
          { value: false, label: 'No' },
        ]}
        onChange={formik.handleChange}
        value={formik?.values?.isTestUser === null ? false : formik?.values?.isTestUser}
        className="input"
        error={formik.touched.isTestUser && formik.errors.isTestUser}
      />
      <RadioButton
        name="gender"
        label="Gender"
        options={[
          { value: '男', label: 'Male' },
          { value: '女', label: 'Female' },
          { value: '無回答', label: 'No answer' },
        ]}
        onChange={formik.handleChange}
        value={formik?.values?.gender}
        className="input"
        error={formik.touched.gender && formik.errors.gender}
      />
      <div className="date-wrapper">
        <TextField
          required
          name="year"
          className="input"
          label="Date of Birth"
          type="select"
          options={getDate('year')}
          value={formik?.values?.year}
          onChange={(e: any) => dateChange('year', e.value)}
          error={formik.touched.year && formik.errors.year}
        />
        <TextField
          name="month"
          label=" "
          className="input right-field"
          type="select"
          options={getDate('month')}
          value={formik?.values?.month}
          onChange={(e: any) => dateChange('month', e.value)}
          error={formik.touched.month && formik.errors.month}
        />
        <TextField
          name="day"
          label=" "
          className="input right-field"
          type="select"
          options={getDate('day').reverse()}
          value={formik?.values?.day}
          onChange={(e: any) => dateChange('day', e.value)}
          error={formik.touched.day && formik.errors.day}
        />
      </div>
      <TextField
        count
        required
        maxlength={7}
        name="postcode"
        label="PO. BOX"
        className="input"
        value={formik?.values?.postcode}
        onChange={formik.handleChange}
        error={formik.touched.postcode && formik.errors.postcode}
      />
      <div className="grid">
        <TextField
          count
          required
          maxlength={100}
          name="address"
          label="Address One"
          className="input"
          value={formik?.values?.address ?? ''}
          onChange={formik.handleChange}
          error={formik.touched.address && formik.errors.address}
        />
        <TextField
          count
          maxlength={100}
          name="address1"
          label="Address Two"
          className="input right-field"
          value={formik?.values?.address1 ?? ''}
          onChange={formik.handleChange}
          error={formik.touched.address1 && formik.errors.address1}
        />
      </div>
      <div className="delivery-address">
        <Checkbox
          label="Delivery Address same as address above"
          name="check"
          onChange={() => setIsChecked(!isChecked)}
          checked={isChecked}
        />

        <TextField
          count
          maxlength={7}
          name="mailPostcode"
          label="Delivery PO. BOX"
          value={formik?.values?.mailPostcode ?? ''}
          onChange={formik.handleChange}
          error={formik.touched.mailPostcode && formik.errors.mailPostcode}
        />

        <div className="grid">
          <TextField
            count
            maxlength={100}
            name="mailAddress"
            label="Delivery Address One"
            value={formik?.values?.mailAddress ?? ''}
            onChange={formik.handleChange}
            error={formik.touched.mailAddress && formik.errors.mailAddress}
          />
          <TextField
            count
            maxlength={100}
            name="mailAddress1"
            label="Delivery Address Two"
            className="right-field"
            value={formik?.values?.mailAddress1 ?? ''}
            onChange={formik.handleChange}
            error={formik.touched.mailAddress1 && formik.errors.mailAddress1}
          />
        </div>
      </div>
      <div className="phone-section">
        <TextField
          required
          maxlength={3}
          name="ext_phone"
          label="Phone Number"
          className="input"
          value={formik?.values?.ext_phone}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            formik.handleChange(e);
          }}
          onKeyDown={(e: React.ChangeEvent<HTMLInputElement>) => {
            phoneChangeHandler(e);
          }}
          error={formik.touched.ext_phone && formik.errors.ext_phone}
          ref={textField1}
        />
        <span>-</span>
        <TextField
          label=" "
          maxlength={4}
          name="first_phone"
          className="input"
          value={formik?.values?.first_phone}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            formik.handleChange(e);
          }}
          onKeyDown={(e: React.ChangeEvent<HTMLInputElement>) => {
            phoneChangeHandler(e);
          }}
          error={formik.touched.first_phone && formik.errors.first_phone}
          ref={textField2}
        />
        <span>-</span>
        <TextField
          label=" "
          maxlength={4}
          name="second_phone"
          className="input"
          value={formik?.values?.second_phone}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            formik.handleChange(e);
          }}
          onKeyDown={(e: React.ChangeEvent<HTMLInputElement>) => {
            phoneChangeHandler(e);
          }}
          error={formik.touched.second_phone && formik.errors.second_phone}
          ref={textField3}
        />
      </div>
      <TextField
        disabled
        name="discordUser"
        label="Discord username"
        className="input disabled"
        value={formik?.values?.discordUser}
        onChange={formik.handleChange}
        error={formik.touched.discordUser && formik.errors.discordUser}
      />
      {renderTshirtSizeField()}
      {renderOtherField()}
      <TextField
        count
        maxlength={100}
        name="occupation"
        label="Occupation"
        className="input"
        value={formik?.values?.occupation ?? ''}
        onChange={formik.handleChange}
        error={formik.touched.occupation && formik.errors.occupation}
      />
      <TextField
        count
        maxlength={1000}
        name="remarks"
        label="Remarks"
        className="input"
        type="textarea"
        rows={10}
        value={formik?.values?.remarks ?? ''}
        onChange={formik.handleChange}
        error={formik.touched.remarks && formik.errors.remarks}
      />
      <div className="button-wrapper">
        <Button className="btn" size="large" type="submit" disabled={isMutating}>
          Update
        </Button>
      </div>
    </Form>
  );
};

export { TeamMemberManagementForm };
