import React from 'react';
import styled from 'styled-components';

import DateInput from '../../atoms/DateInput';
import { isoToDateObject } from '../../../utils/dateUtils';
import { TextField, Button, RadioButton } from '../../../components';

interface Props {
  notificationData?: any;
  loading?: boolean;
  formik?: any;
}

const Form = styled.form`
  .textfield {
    margin-bottom: 20px;
  }

  .button-wrapper {
    display: flex;
    flex-direction: row-reverse;
    .submit-button {
      width: 245px;
      margin-top: 20px;
    }
  }
`;

const TeamNotificationForm: React.FC<Props> = ({ loading = false, formik }) => {
  const handleDateChange = (name: string, value: Date | null) => {
    formik.setFieldValue(name, value);
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <TextField
        count
        required
        name="title"
        label="Title"
        maxlength={100}
        className="textfield"
        value={formik.values.title}
        onChange={formik.handleChange}
        error={formik.touched.title && formik.errors.title}
      />
      <TextField
        count
        required
        name="content"
        maxlength={500}
        label="Contents"
        type="textarea"
        className="textfield"
        onChange={formik.handleChange}
        value={formik.values.content}
        error={formik.touched.content && formik.errors.content}
      />
      <TextField
        label="URL Title"
        name="urlTitle"
        maxlength={500}
        onChange={formik.handleChange}
        value={formik.values.urlTitle}
        className="textfield"
        error={formik.touched.urlTitle && formik.errors.urlTitle}
      />
      <TextField
        label="URL"
        name="url"
        maxlength={1000}
        onChange={formik.handleChange}
        value={formik.values.url}
        className="textfield"
        error={formik.touched.url && formik.errors.url}
      />
      <RadioButton
        name="status"
        label="Status"
        required
        options={[
          { value: true, label: 'Public' },
          { value: false, label: 'Private' },
        ]}
        onChange={formik?.handleChange}
        value={formik.values.status}
        className="textfield"
        error={formik.touched.status && formik.errors.status}
      />
      <DateInput
        name="publishDate"
        label="Published Date"
        value={isoToDateObject(formik.values.publishDate)}
        onChange={handleDateChange}
        error={formik.touched.publishDate && formik.errors.publishDate}
        required
      />

      <div className="button-wrapper">
        <Button size="large" className="submit-button" disabled={loading} type="submit">
          Save
        </Button>
      </div>
    </Form>
  );
};

export { TeamNotificationForm };
