import * as yup from 'yup';
import { useState } from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

import { authContext } from '../utils';
import { addNewAdminUser } from '../services';
import { useToast } from '../hooks/useToast';
import { Header, Toast } from '../components';
import { SuperAdminUserManagementForm } from '../components/organisms/SuperAdminUserManagementForm';

const Container = styled.div``;

const SuperAdminAddUserManagement = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = authContext();

  const [showToast, setShowToast] = useToast(false);
  const [errMsg, setErrMsg] = useState<string>('Something went wrong');

  const initialValues = {
    username: '',
    name_sei: '',
    name_mei: '',
    furigana_sei: '',
    furigana_mei: '',
    email: '',
    phone: '',
    department: '',
    teamId: '',
  };

  const validationSchema = yup.object().shape({
    username: yup.string().required('Please enter'),
    name_sei: yup.string().required('Please enter'),
    name_mei: yup.string().required('Please enter'),
    furigana_sei: yup
      .string()
      .matches(/^[ぁ-んー]+$/, { message: 'Invalid furigana' })
      .required('Please enter'),
    furigana_mei: yup
      .string()
      .matches(/^[ぁ-んー]+$/, { message: 'Invalid furigana' })
      .required('Please enter'),
    email: yup.string().email('Invalid email address').required('Please enter'),
    phone: yup.number().required('Please enter').typeError('Please enter a number'),
    department: yup.string().required('Please enter'),
    // HIDE FOR NOW
    // adminType: yup.string().required('Please enter'),
    // teamId: yup.string().when('adminType', {
    //   is: 'faniqueAdmin',
    //   then: yup.string().notRequired(),
    //   otherwise: yup.string().required('Please enter'),
    // }),
    teamId: yup.string().required('Please enter'),
  });

  const formik: any = useFormik<any>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      /* Until further API update */
      mutate({ ...values });
    },
  });

  const { mutate, isLoading: isMutating } = useMutation(addNewAdminUser, {
    onSuccess: () => {
      history.push('/user-management');
    },
    onError: (e: any) => {
      setErrMsg(e?.response?.data?.message || 'Something went wrong');
      setShowToast(true);
    },
  });

  return (
    <Container>
      <Header title="Add User" backButton />
      <Toast show={showToast} setShow={setShowToast} message={t(errMsg)} />
      <div className="body-border">
        <SuperAdminUserManagementForm formik={formik} type="add" isMutating={isMutating} />
      </div>
    </Container>
  );
};
export default SuperAdminAddUserManagement;
