import { IonToast } from '@ionic/react';
import React from 'react';

interface Props {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  message: string;
}

export const Toast: React.FC<Props> = ({ show, setShow, message }) => {
  return (
    <IonToast
      isOpen={show}
      onDidDismiss={() => setShow(false)}
      message={message}
      duration={3000}
      position="top"
      cssClass="custom-toast"
    />
  );
};
