import React from 'react';
import { IonIcon } from '@ionic/react';
import styled from 'styled-components';
import { ImageUpload } from '../ImageUpload';
import { useTranslation } from 'react-i18next';

interface Props {
  icon?: string;
  label: string;
  bgColor?: string;
  handleDelete?: (e?: any, fileSrc?: any) => void;
  handleUpload?: (url: string, fileFormat: string, file: any, fetch: boolean) => void;
  variant?: 'upload' | 'delete';
  className?: string;
}

const BoxWrapper = styled.div<{ bgColor?: string; variant?: string }>`
  width: 100px;
  height: 100px;
  border: 1px solid #686767;
  background-color: rgba(155, 155, 155, 0.35);
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
  border-radius: 12px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icon-wrapper {
    width: 36px;
    height: 36px;
    background-color: ${(props) => (props.bgColor ? props.bgColor : '#29c8d2')};
    border-radius: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;

    ion-icon {
      margin-top: ${(props) => (props?.variant === 'upload' ? '3px' : 0)};
    }
  }

  span {
    font-weight: 400;
    font-size: 10px;
    color: #ffffff;
    text-align: center;
  }
`;

const BoxComponent: React.FC<Props> = ({
  icon,
  label,
  bgColor,
  handleDelete,
  handleUpload,
  variant = 'upload',
  className,
}) => {
  const { t } = useTranslation();
  return (
    <BoxWrapper bgColor={bgColor} variant={variant} className={className}>
      <div className="icon-wrapper">
        {variant === 'upload' ? (
          <ImageUpload variant="secondary" handleUpload={handleUpload} />
        ) : (
          <IonIcon icon={icon} onClick={handleDelete} />
        )}
      </div>
      <span>{t(label)}</span>
    </BoxWrapper>
  );
};

export { BoxComponent };
