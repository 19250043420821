import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';

import { useToast } from '../hooks/useToast';
import { editTeamUser, fetchSingleTeamUserData } from '../services';
import { EditAdminUserTemplate } from '../components/organisms/CommonTemplate/UserManagement/EditAdminUserTemplate';

const EditAdminUser = () => {
  const history = useHistory();
  const id = useParams<{ id: string; slug: string }>();

  const [showToast, setShowToast] = useToast(false);
  const [errMsg, setErrMsg] = useState<string>('Something went wrong');

  const { data, isFetching } = useQuery(['singleUserData', id.slug], fetchSingleTeamUserData, {
    enabled: !!id.slug,
    select: (response) => {
      return response?.data;
    },
  });

  const { mutate, isLoading: isMutating } = useMutation(editTeamUser, {
    onSuccess: () => {
      history.push(`/${id.id}/user-management`);
    },
    onError: (e: any) => {
      setErrMsg(e?.response?.data?.message || 'Something went wrong');
      setShowToast(true);
    },
  });
  return (
    <EditAdminUserTemplate
      data={data}
      id={id.slug}
      showToast={showToast}
      setShowToast={setShowToast}
      errMsg={errMsg}
      isFetching={isFetching}
      isMutating={isMutating}
      mutate={mutate}
    />
  );
};

export default EditAdminUser;
