import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

import { Toast } from '../components';
import { addTeamNotification } from '../services';
import { AddAdminNotificationTemplate } from '../components/organisms/CommonTemplate/Notification/AddAdminNotificationTemplate';

const AddNotification = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [showToast, setShowToast] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('Something went wrong');

  const { mutate, isLoading: isMutating } = useMutation(addTeamNotification, {
    onSuccess: async () => {
      history.push({
        pathname: `/${pathname.split('/')[1]}/notification`,
        search: '?list=beforePublishing',
      });
    },
    onError: (e: any) => {
      setErrorMsg(e?.response?.data?.message);
      setShowToast(true);
    },
  });

  return (
    <>
      <Toast show={showToast} setShow={setShowToast} message={t(errorMsg)} />
      <AddAdminNotificationTemplate
        teamId={pathname?.split('/')[1]}
        mutate={mutate}
        isMutating={isMutating}
      />
    </>
  );
};

export default AddNotification;
