import React, { useState } from 'react';
import { useToast } from '../hooks/useToast';
import { authContext, USER_ROLES } from '../utils';
import { useQuery, useMutation } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router';
import { editTeamVote, fetchSingleTeamVoteData } from '../services';
import { EditAdminVoteTemplate } from '../components/organisms/CommonTemplate/VoteManagement/EditAdminVoteTemplate';

const AdminEditVote = () => {
  const history = useHistory();
  const id = useParams<{ id: string; slug: string }>();
  const { user } = authContext();
  const { search } = useLocation();
  const [showToast, setShowToast] = useToast(false);
  const [errMsg, setErrMsg] = useState<string>('Something went wrong');

  const { data, isLoading, isFetching } = useQuery(
    ['singleTeamVoteData', id.slug],
    fetchSingleTeamVoteData,
    {
      enabled:
        !!id.slug &&
        (user?.role === USER_ROLES.ADMIN ||
          user?.role === USER_ROLES.TEAM_ADMIN ||
          user?.role === USER_ROLES.TEAM_USER),
      select: (response) => {
        return response?.data;
      },
    }
  );

  const { mutate, isLoading: isMutating } = useMutation(editTeamVote, {
    onSuccess: async () => {
      history.push({
        pathname: `/${id.id}/vote-management`,
        search: `?list=${search?.split('?list=')[1] || 'onGoingVote'}`,
      });
    },
    onError: (e: any) => {
      setErrMsg(e?.response?.data?.message || 'Something went wrong');
      setShowToast(true);
    },
  });

  return (
    <EditAdminVoteTemplate
      showToast={showToast}
      setShowToast={setShowToast}
      isLoading={isLoading}
      isFetching={isFetching}
      isMutating={isMutating}
      mutate={mutate}
      data={data}
      errMsg={errMsg}
    />
  );
};

export default AdminEditVote;
