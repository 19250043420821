import * as yup from 'yup';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Header, Toast, VoteManagementForm } from '../../../../components';
import { getImageSelectionsStringified, authContext } from '../../../../utils';

interface Props {
  mutate: any;
  isMutating: boolean;
  showToast: boolean;
  setShowToast: React.Dispatch<React.SetStateAction<boolean>>;
  errMsg: any;
}

const Container = styled.div`
  ion-radio {
    --color-checked: #29c8d2;
  }
`;

const AddAdminVoteTemplate = ({ mutate, isMutating, showToast, setShowToast, errMsg }: Props) => {
  const { user } = authContext();
  const [teamId, setTeamId] = useState<string>('');
  const { t } = useTranslation();

  const initialValues = {
    id: '',
    title: '',
    content: '',
    selectionType: '',
    selections: '{"0":"","1":""}',
    images: [],
    status: '',
    showResult: '',
    publishDate: '',
    closeDate: '',
    membershipStatus: [],
  };

  const validationSchema = yup.object().shape({
    title: yup.string().required('Please enter'),
    content: yup.string().required('Please enter'),
    selectionType: yup.string().required('Please enter'),
    selections: yup.object().test({
      test: (obj, context) => {
        if (
          context?.parent['selectionType'] === 'SingleSelect' ||
          context?.parent['selectionType'] === 'MultipleSelect'
        ) {
          for (const key in obj) {
            if (!obj[key]) {
              return false;
            }
          }
          return true;
        } else return true;
      },
      message: 'Please enter',
    }),
    images: yup.array().when('selectionType', {
      is: 'ImageSelect',
      then: yup.array().min(1, 'Please enter'),
    }),
    status: yup.boolean().required('Please enter'),
    showResult: yup.boolean().required('Please enter'),
    publishDate: yup.string().nullable().required('Please enter'),
    closeDate: yup.string().nullable().required('Please enter'),
    membershipStatus: yup.array().required('Please select').min(1, 'Please select'),
  });

  const formik: any = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      mutate({
        teamId: teamId ? teamId : user?.teamId,
        data: {
          title: values?.title,
          content: values?.content,
          selectionType: values?.selectionType,
          selections: getSelections(),
          status: values?.status,
          publishDate: values?.publishDate,
          closeDate: values?.closeDate,
          showResult: values?.showResult,
          target: {
            StatusIds: values?.membershipStatus,
          },
        },
      });
    },
  });

  const getSelections = (): any => {
    if (
      formik?.values?.selectionType === 'SingleSelect' ||
      formik?.values?.selectionType === 'MultipleSelect'
    ) {
      return formik?.values?.selections ? JSON.parse(formik?.values?.selections) : {};
    } else {
      return formik?.values?.images
        ? JSON.parse(getImageSelectionsStringified(formik.values.images))
        : {};
    }
  };

  useEffect(() => {
    if (location.pathname.split('/')[1] !== 'vote-management') {
      setTeamId(location.pathname.split('/')[1]);
    }
  }, [location.pathname]);

  return (
    <Container>
      <Header title={'Add'} backButton />
      <Toast show={showToast} setShow={setShowToast} message={t(errMsg)} />
      <div className="body-border">
        <VoteManagementForm formik={formik} loading={isMutating} />
      </div>
    </Container>
  );
};

export { AddAdminVoteTemplate };
