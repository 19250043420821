import * as yup from 'yup';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import {
  Toast,
  Header,
  Loader,
  AccountStatusModal,
  TeamUserManagementForm,
} from '../../../../components';

interface Props {
  id: string;
  data: any;
  showToast: boolean;
  setShowToast: React.Dispatch<React.SetStateAction<boolean>>;
  errMsg: string;
  isFetching: boolean;
  isMutating: boolean;
  mutate: any;
}

const Container = styled.div`
  .add-section {
    border: none;
    .add-icon {
      display: none;
    }
    .label {
      color: #b80629;
      font-weight: 400;
      font-size: 16px;
      text-decoration: underline;
    }
  }
`;

const EditAdminUserTemplate = ({
  id,
  data,
  showToast,
  setShowToast,
  errMsg,
  isFetching,
  isMutating,
  mutate,
}: any) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [initialValues, setInitialValues] = useState<any>({
    username: '',
    name_sei: '',
    name_mei: '',
    furigana_sei: '',
    furigana_mei: '',
    email: '',
    phone: '',
    department: '',
    password: '',
    cognitoAccountStatus: false,
  });

  const validationSchema = yup.object().shape({
    username: yup.string().required('Please enter'),
    name_sei: yup.string().required('Please enter'),
    name_mei: yup.string().required('Please enter'),
    furigana_sei: yup
      .string()
      .matches(/^[ぁ-んー]+$/, { message: 'Invalid furigana' })
      .required('Please enter'),
    furigana_mei: yup
      .string()
      .matches(/^[ぁ-んー]+$/, { message: 'Invalid furigana' })
      .required('Please enter'),
    email: yup.string().email('Invalid email address').required('Please enter'),
    phone: yup.number().required('Please enter').typeError('Please enter a number'),
    password: yup.string().min(8, '※Please enter more than 8 characters'),
    cognitoAccountStatus: yup.boolean().required('Please enter'),
  });

  const formik = useFormik<any>({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let payload = {
        id,
        data: {
          id,
          username: values?.username,
          name_sei: values?.name_sei,
          name_mei: values?.name_mei,
          furigana_sei: values?.furigana_sei,
          furigana_mei: values?.furigana_mei,
          email: values?.email,
          phone: values?.phone,
          department: values?.department,
          password: values?.password,
        },
      };
      if (!values?.password) delete payload?.data?.password;
      mutate(payload);
    },
  });

  const handleModalState = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (data) {
      setInitialValues({ ...data, password: '' });
    }
  }, [data]);

  return (
    <Container>
      <Toast show={showToast} setShow={setShowToast} message={t(errMsg)} />
      <AccountStatusModal isOpen={isModalOpen} onCloseModal={onCloseModal} formik={formik} />
      <Header
        title="Edit User"
        backButton
        addLabel="Change Account Status"
        onClick={handleModalState}
      />
      <div className="body-border">
        {isFetching ? (
          <Loader hasWrapper />
        ) : (
          <TeamUserManagementForm formik={formik} isMutating={isMutating} type="edit" />
        )}
      </div>
    </Container>
  );
};

export { EditAdminUserTemplate };
