import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';

import { useToast } from '../hooks/useToast';
import { editTeamNotification, fetchSingleNotificationTeam } from '../services';
import { EditAdminNotificationTemplate } from '../components/organisms/CommonTemplate/Notification/EditAdminNotificationTemplate';

const TeamEditNotification = () => {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const id = pathname.split('/')[3];

  const [showToast, setShowToast] = useToast(false);

  const { mutate, isLoading: isMutating } = useMutation(editTeamNotification, {
    onSuccess: async () => {
      history.push({
        pathname: `/notification`,
        search: `?list=${search?.split('?list=')[1] || 'beforePublishing'}`,
      });
    },
    onError: (e: any) => {
      setShowToast(true);
    },
  });

  const { data, isLoading, isFetching } = useQuery(
    ['fetchSingleNotification', id],
    fetchSingleNotificationTeam,
    {
      enabled: !!id,
      select: (response) => {
        return response?.data;
      },
    }
  );

  return (
    <EditAdminNotificationTemplate
      data={data}
      showToast={showToast}
      setShowToast={setShowToast}
      isLoading={isLoading}
      isFetching={isFetching}
      isMutating={isMutating}
      mutate={mutate}
    />
  );
};

export default TeamEditNotification;
