import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { addTeamVote } from '../services';
import { useToast } from '../hooks/useToast';
import { AddAdminVoteTemplate } from '../components/organisms/CommonTemplate/VoteManagement/AddAdminVoteTemplate';

const AddVote = () => {
  const history = useHistory();

  const [showToast, setShowToast] = useToast(false);
  const [errMsg, setErrMsg] = useState<string>('Something went wrong');

  const { mutate, isLoading: isMutating } = useMutation(addTeamVote, {
    onSuccess: async () => {
      history.push({
        pathname: `/vote-management`,
        search: '?list=onGoingVote',
      });
    },
    onError: (e: any) => {
      setErrMsg(e?.response.data.message || 'Something went wrong');
      setShowToast(true);
    },
  });

  return (
    <AddAdminVoteTemplate
      mutate={mutate}
      isMutating={isMutating}
      showToast={showToast}
      setShowToast={setShowToast}
      errMsg={errMsg}
    />
  );
};

export default AddVote;
