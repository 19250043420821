import React, { useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';

import { Header, Loader, TeamMemberManagementForm, Toast } from '../../../../components';
import { useMutation } from 'react-query';
import { postGift, updateUserGiftInfo } from '../../../../services/gift';
import { useTranslation } from 'react-i18next';

interface Props {
  fetchedData: any;
  showToast: boolean;
  setShowToast: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading?: boolean;
  isMutating?: boolean;
  mutate?: any;
  formDetails?: any;
  userGiftData?: any;
  masterId?: any;
  tshirtId?: any;
  userId?: any;
  uniformId?: any;
  refetch?: any;
}

const EditMemberManagementTemplate = ({
  fetchedData,
  showToast,
  setShowToast,
  isLoading,
  isMutating,
  mutate,
  formDetails,
  userGiftData,
  masterId,
  tshirtId,
  userId,
  uniformId,
  refetch,
}: Props) => {
  const [error, setError] = useState<string>('');
  const { t } = useTranslation();
  const [formValueDetails, setFormValueDetails] = useState<any>({});

  const validationSchema = yup.object().shape({
    postcode: yup
      .string()
      .matches(/^\d{7}$/, { message: '郵便番号は正しくありません' })
      .required('Please enter'),
    address: yup.string().required('Please enter'),
    ext_phone: yup
      .string()
      .matches(/^[0-9]+$/, '番号のみ許可')
      .required('Please enter'),
    first_phone: yup
      .string()
      .matches(/^[0-9]+$/, '番号のみ許可')
      .required('Please enter'),
    second_phone: yup
      .string()
      .matches(/^[0-9]+$/, '番号のみ許可')
      .required('Please enter'),
    year: yup.string().required('Please enter'),
    month: yup.string().required('Please enter'),
    day: yup.string().required('Please enter'),
    mailPostcode: yup
      .string()
      .matches(/^\d{7}$/, { message: '郵便番号は正しくありません' })
      .nullable(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...fetchedData, ...formDetails } ?? { gender: '' },
    validateOnBlur: false,
    validateOnChange: true,
    validationSchema,
    onSubmit: async (values) => {
      const {
        id,
        address,
        address1,
        postcode,
        year,
        month,
        day,
        mailPostcode,
        mailAddress,
        mailAddress1,
        ext_phone,
        first_phone,
        second_phone,
        discordUser,
        gender,
        occupation,
        remarks,
        isTestUser,
      } = values;
      setFormValueDetails(values);
      if (fetchedData?.statusNameEnglish !== 'RED' && fetchedData?.statusNameEnglish !== 'SILVER') {
        if (userGiftData?.data?.gifts?.length === 0) {
          await postGiftMutate({
            masterId: masterId?.tshirtMasterId,
            userId: userId,
            choice: {
              size: values.shirtSize,
            },
          });
        } else {
          await giftMutation({
            id: tshirtId,
            userId: userId,
            choice: {
              size: values.shirtSize,
            },
          });
        }
      } else {
        mutate({
          id,
          address,
          address1,
          postcode,
          birthday: `${year}/${month}/${day}`,
          mailPostcode,
          mailAddress,
          mailAddress1,
          phone: `${ext_phone}${first_phone}${second_phone}`,
          discordUser,
          gender,
          occupation,
          remarks,
          isTestUser,
        });
      }
    },
  });

  const { mutate: postGiftMutate, isLoading: postGiftLoading } = useMutation(postGift, {
    onSuccess: async () => {
      if (fetchedData?.statusNameEnglish === 'GOLD') {
        refetch();
        mutate({
          id: formValueDetails?.id,
          address: formValueDetails?.address,
          address1: formValueDetails?.address1,
          postcode: formValueDetails?.postcode,
          birthday: `${formValueDetails?.year}/${formValueDetails?.month}/${formValueDetails?.day}`,
          mailPostcode: formValueDetails?.mailPostcode,
          mailAddress: formValueDetails?.mailAddress,
          mailAddress1: formValueDetails?.mailAddress1,
          phone: `${formValueDetails?.ext_phone}${formValueDetails?.first_phone}${formValueDetails?.second_phone}`,
          discordUser: formValueDetails?.discordUser,
          gender: formValueDetails?.gender,
          occupation: formValueDetails?.occupation,
          remarks: formValueDetails?.remarks,
          isTestUser: formValueDetails?.isTestUser,
        });
      } else {
        if (formValueDetails?.uniformName === '') {
          postUniformMutate({
            masterId: masterId?.uniformMasterId ?? '',
            userId: userId,
            choice: {
              size: formValueDetails?.uniformSize,
              number: formValueDetails?.uniformNo,
            },
          });
        } else {
          postUniformMutate({
            masterId: masterId?.uniformMasterId,
            userId: userId,
            choice: {
              size: formValueDetails?.uniformSize,
              number: formValueDetails?.uniformNo,
              name: formValueDetails?.uniformName,
            },
          });
        }
      }
    },
    onError: (err: any) => {
      setError(err?.response?.data?.message ?? t('Something went wrong. Please try again.'));
      setShowToast(true);
    },
  });

  const { mutate: postUniformMutate, isLoading: postUniformLoading } = useMutation(postGift, {
    onSuccess: async () => {
      refetch();
      mutate({
        id: formValueDetails?.id,
        address: formValueDetails?.address,
        address1: formValueDetails?.address1,
        postcode: formValueDetails?.postcode,
        birthday: `${formValueDetails?.year}/${formValueDetails?.month}/${formValueDetails?.day}`,
        mailPostcode: formValueDetails?.mailPostcode,
        mailAddress: formValueDetails?.mailAddress,
        mailAddress1: formValueDetails?.mailAddress1,
        phone: `${formValueDetails?.ext_phone}${formValueDetails?.first_phone}${formValueDetails?.second_phone}`,
        discordUser: formValueDetails?.discordUser,
        gender: formValueDetails?.gender,
        occupation: formValueDetails?.occupation,
        remarks: formValueDetails?.remarks,
        isTestUser: formValueDetails?.isTestUser,
      });
    },
    onError: (err: any) => {
      setError(err?.response?.data?.message ?? t('Something went wrong. Please try again.'));
      setShowToast(true);
    },
  });

  // For Update PUT API
  const { mutate: uniformGiftMutation, isLoading: uniformGiftloading } = useMutation(
    updateUserGiftInfo,
    {
      onSuccess: () => {
        mutate({
          id: formValueDetails?.id,
          address: formValueDetails?.address,
          address1: formValueDetails?.address1,
          postcode: formValueDetails?.postcode,
          birthday: `${formValueDetails?.year}/${formValueDetails?.month}/${formValueDetails?.day}`,
          mailPostcode: formValueDetails?.mailPostcode,
          mailAddress: formValueDetails?.mailAddress,
          mailAddress1: formValueDetails?.mailAddress1,
          phone: `${formValueDetails?.ext_phone}${formValueDetails?.first_phone}${formValueDetails?.second_phone}`,
          discordUser: formValueDetails?.discordUser,
          gender: formValueDetails?.gender,
          occupation: formValueDetails?.occupation,
          remarks: formValueDetails?.remarks,
          isTestUser: formValueDetails?.isTestUser,
        });
      },
      onError: (err: any) => {
        setError(err?.response?.data?.message || `${t('Something went wrong. Please try again.')}`);
        setShowToast(true);
      },
    }
  );
  const { mutate: giftMutation, isLoading: loadingGiftMutation } = useMutation(updateUserGiftInfo, {
    onSuccess: () => {
      if (fetchedData?.statusNameEnglish === 'GOLD') {
        mutate({
          id: formValueDetails?.id,
          address: formValueDetails?.address,
          address1: formValueDetails?.address1,
          postcode: formValueDetails?.postcode,
          birthday: `${formValueDetails?.year}/${formValueDetails?.month}/${formValueDetails?.day}`,
          mailPostcode: formValueDetails?.mailPostcode,
          mailAddress: formValueDetails?.mailAddress,
          mailAddress1: formValueDetails?.mailAddress1,
          phone: `${formValueDetails?.ext_phone}${formValueDetails?.first_phone}${formValueDetails?.second_phone}`,
          discordUser: formValueDetails?.discordUser,
          gender: formValueDetails?.gender,
          occupation: formValueDetails?.occupation,
          remarks: formValueDetails?.remarks,
          isTestUser: formValueDetails?.isTestUser,
        });
      } else if (
        formValueDetails?.statusNameEnglish !== 'RED' &&
        formValueDetails?.statusNameEnglish !== 'SILVER' &&
        formValueDetails?.statusNameEnglish !== 'GOLD'
      ) {
        if (formValueDetails?.uniformName === '') {

          uniformGiftMutation({
            id: uniformId,
            userId: userId,
            choice: {
              size: formValueDetails?.uniformSize,
              number: formValueDetails?.uniformNo,
            },
          });
        } else {

          uniformGiftMutation({
            id: uniformId,
            userId: userId,
            choice: {
              size: formValueDetails?.uniformSize,
              number: formValueDetails?.uniformNo,
              name: formValueDetails?.uniformName,
            },
          });
        }
      }
    },
    onError: (err: any) => {
      setError(err?.response?.data?.message || `${t('Something went wrong. Please try again.')}`);
      setShowToast(true);
    },
  });


  return (
    <div>
      <Toast show={showToast} setShow={setShowToast} message={error || 'Something went wrong'} />
      <Header title="Update Member" backButton />
      <div className="body-border">
        {isLoading ? (
          <Loader hasWrapper />
        ) : (
          <TeamMemberManagementForm
            formik={formik}
            isMutating={
              isMutating ||
              loadingGiftMutation ||
              uniformGiftloading ||
              postGiftLoading ||
              postUniformLoading
            }
            statusName={fetchedData?.statusNameEnglish}
          />
        )}
      </div>
    </div>
  );
};

export { EditMemberManagementTemplate };
