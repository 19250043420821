import styled from 'styled-components';
import React, { useEffect } from 'react';
import DateInput from '../../atoms/DateInput';
import { useTranslation } from 'react-i18next';
import { IonLabel, IonNote } from '@ionic/react';
import { useMutation, useQuery } from 'react-query';
import { authContext, USER_ROLES } from '../../../utils';
import { isoToDateObject } from '../../../utils/dateUtils';
import { useImageUpload } from '../../../hooks/useImageUpload';
import { UploadImageUrlResponse } from '../../../types/image-upload';
import { getUploadImageUrl, uploadImage } from '../../../services/image-upload';
import {
  Button,
  TextField,
  RadioButton,
  ImageOptions,
  TextFieldOptions,
} from '../../../components';

interface Props {
  formik: any;
  loading?: boolean;
}

const Wrapper = styled.div`
  .options-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    margin-bottom: 20px;

    .text-wrapper {
      position: relative;

      .close-icon {
        position: absolute;
        top: 22px;
        right: 20px;
        z-index: 2;
        font-size: 20px;
        cursor: pointer;
      }

      .option-textfield {
        margin-right: 15px;
      }
      .has-close-icon input {
        padding-right: 30px;
      }
    }

    span {
      margin-bottom: 20px;
      cursor: pointer;
      display: flex;
      align-items: center;

      .icon {
        background-color: #aee7eb;
        border-radius: 50%;
        color: #29c8d2;
        font-size: 22px;
        margin-right: 8px;
      }
    }
  }
  .textfield {
    margin-bottom: 20px;

    .select__multi-value {
      svg {
        fill: #29c8d2;
      }
    }
  }

  ion-label {
    margin-right: 1em;
    margin-bottom: 0.6em;
    font-weight: 400;
    font-size: 14px;

    .required {
      color: #b80629;
      margin-left: 3px;
    }

    .info {
      color: #b80629;
      margin-bottom: 0px;
    }
  }

  .button-wrapper {
    display: flex;
    flex-direction: row-reverse;
    .submit-button {
      width: 245px;
    }
  }

  .checkbox-container {
    margin-bottom: 12px;
    .checkboxes {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0.6em;

      .checkbox-wrapper {
        min-width: 8.7em;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        margin-right: 10px;
        input {
          margin-right: 8px;
          width: 1.4em;
          height: 1.4em;
          border-radius: 0.25em;
          border: 1px solid #828585;
          outline: none;
          cursor: pointer;
        }

        .label {
          font-size: 14px;
        }
      }
    }
  }

  ion-note {
    color: red;
    padding-top: 0;
    font-weight: 100;
  }

  .bottom-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .note {
    ion-label {
      color: #b80629;
      font-size: 14px;
      opacity: 0.7;
    }
    margin-bottom: 10px;
  }
`;

const VoteManagementForm: React.FC<Props> = ({ formik, loading = false }) => {
  const { user } = authContext();
  const { t } = useTranslation();
  const membershipCardIds: any = {
    Red: '1f0d669c-ebad-47c7-9829-1c6de99ea720',
    Silver: 'abe02ca5-97fe-4db1-aa6c-c8b3771258f3',
    Gold: '7c883224-4a1a-41d4-974a-9f81d57353e0',
    Platinum: '35f41cc4-e701-4007-b48e-43d25ccdddbd',
    'Platinum Fire': '13d8cc19-6ed2-46c7-9dbb-7ae341cd1d1a',
    Diamond: '27556db2-76cc-4d2e-9888-42bdc5346227',
  };
  // For Image Upload
  const {
    urlPath,
    setUrlPath,
    fileFormat,
    setFileFormat,
    file,
    setFile,
    fetchUpload,
    setFetchUpload,
  } = useImageUpload();

  const onTeamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('team', e);
  };

  const onVoteTypeChange = (e: any) => {
    formik.setFieldValue('selectionType', e?.value);
  };

  const handleDateChange = (name: string, value: Date | null) => {
    formik.setFieldValue(name, value);
  };

  const handleCheckbox = (e: any, c: any) => {
    if (e.target.checked) {
      formik?.setFieldValue('membershipStatus', [
        ...formik.values.membershipStatus,
        membershipCardIds[c],
      ]);
    } else {
      formik?.setFieldValue(
        'membershipStatus',
        formik?.values?.membershipStatus?.filter((d: string) => d !== membershipCardIds[c])
      );
    }
  };

  const handleCheckAll = (e: any) => {
    if (e.target.checked) {
      const allCards = Object.keys(membershipCardIds).map((c: string) => membershipCardIds[c]);
      formik.setFieldValue('membershipStatus', allCards);
    } else {
      formik.setFieldValue('membershipStatus', []);
    }
  };

  const handleImageUpload = (url: string, fileFormat: string, file: any, fetch: boolean) => {
    setUrlPath(`teamId/voteId/${url}`);
    setFileFormat(fileFormat);
    setFile(file);
    setFetchUpload(fetch);
  };

  const { data: imageUploadUrl, isFetching: isGettingUrl } = useQuery(
    ['getUploadImageUrl', user?.teamId, urlPath, fileFormat],
    getUploadImageUrl,
    {
      enabled: fetchUpload,
      select: (response) => {
        return response?.data;
      },
      onSuccess: (data) => {
        handleFormData(data);
      },
    }
  );

  const { mutate, isLoading: isUploading, isSuccess } = useMutation(uploadImage);

  const handleFormData = (data: UploadImageUrlResponse) => {
    const formData = new FormData();
    if (data?.fields) {
      formData.append('key', data?.fields?.key ?? '');
      formData.append('bucket', data?.fields?.bucket ?? '');
      formData.append('X-Amz-Algorithm', data?.fields['X-Amz-Algorithm'] ?? '');
      formData.append('X-Amz-Credential', data?.fields['X-Amz-Credential'] ?? '');
      formData.append('X-Amz-Date', data?.fields['X-Amz-Date'] ?? '');
      formData.append('X-Amz-Signature', data?.fields['X-Amz-Signature'] ?? '');
      formData.append('X-Amz-Security-Token', data?.fields['X-Amz-Security-Token'] ?? '');
      formData.append('Policy', data?.fields['Policy'] ?? '');
      formData.append('Content-Type', data?.fields['Content-Type'] ?? '');
      formData.append('file', file);
      mutate({ url: data?.url, data: formData });
    }
  };

  const onUploadSuccess = (fileSrc: string) => {
    formik.setFieldValue('images', [
      ...formik.values.images,
      { id: formik?.values?.images?.length?.toString(), src: fileSrc },
    ]);
  };

  useEffect(() => {
    if (isSuccess) {
      onUploadSuccess(`${imageUploadUrl?.url}${imageUploadUrl?.fields?.key}`);
    }
  }, [isSuccess]);

  const timeFilter = (time: any, name: string) => {
    if (
      (name === 'closeDate' && !formik.values.closeDate) ||
      (name === 'publishDate' && !formik.values.publishDate)
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Wrapper>
      <form onSubmit={formik.handleSubmit}>
        {user?.role === USER_ROLES.SUPER_ADMIN ? (
          <>
            <TextField
              required
              name="team"
              label="Team"
              type="select"
              multiple={true}
              className="textfield"
              placeholder="Select"
              onChange={onTeamChange}
              value={formik.values.team}
              options={[
                { value: 'team1', label: 'Team1' },
                { value: 'team2', label: 'Team2' },
              ]}
              error={formik.touched.team && formik.errors.team}
            />
            <TextField
              count
              required
              name="title"
              label="Title"
              className="textfield"
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && formik.errors.title}
            />
            <TextField
              count
              required
              maxlength={100}
              name="subtitle"
              label="Sub Title"
              className="textfield"
              value={formik.values.subtitle}
              onChange={formik.handleChange}
              error={formik.touched.subtitle && formik.errors.subtitle}
            />
            <RadioButton
              name="type"
              label="Type"
              required
              options={[
                { value: 'select', label: t('Select') },
                { value: 'multi-select', label: t('Multi-Select') },
                { value: 'image-select', label: t('Image-Select') },
              ]}
              onChange={formik.handleChange}
              value={formik.values.type}
              className="textfield"
              error={formik.touched.type && formik.errors.type}
            />
          </>
        ) : (
          <>
            <TextField
              count
              required
              name="title"
              label="Vote Title"
              className="textfield"
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && formik.errors.title}
            />
            <TextField
              count
              required
              maxlength={100}
              name="content"
              label="Vote Contents"
              className="textfield"
              value={formik.values.content}
              onChange={formik.handleChange}
              error={formik.touched.content && formik.errors.content}
            />
            <TextField
              required
              name="selectionType"
              label="Vote Type"
              type="select"
              className="textfield"
              onChange={onVoteTypeChange}
              value={formik.values.selectionType}
              options={[
                { value: 'SingleSelect', label: t('Single Select') },
                { value: 'MultipleSelect', label: t('Multi Select') },
                { value: 'ImageSelect', label: t('Image') },
              ]}
              error={formik.touched.selectionType && formik.errors.selectionType}
            />
          </>
        )}

        {formik.values.selectionType === 'ImageSelect' ? (
          <ImageOptions
            formik={formik}
            handleUpload={handleImageUpload}
            loading={isGettingUrl || isUploading}
            hasClose={false}
          />
        ) : (
          <TextFieldOptions formik={formik} />
        )}

        <RadioButton
          name="status"
          label="Status"
          required
          options={[
            { value: true, label: 'Public' },
            { value: false, label: 'Private' },
          ]}
          onChange={formik?.handleChange}
          value={formik.values.status}
          className="textfield"
          error={formik.touched.status && formik.errors.status}
        />

        {/* This button is for visibility of  vote distribution button in ended votes in nft-owners */}
        <RadioButton
          name="showResult"
          label="Vote distribution information"
          required
          options={[
            { value: true, label: 'On' },
            { value: false, label: 'Off' },
          ]}
          onChange={formik?.handleChange}
          value={formik.values.showResult}
          className="textfield"
          error={formik.touched.showResult && formik.errors.showResult}
        />

        <div className="checkbox-container">
          <IonLabel>
            {t('Membership Status')}
            <span className="required">*</span>
          </IonLabel>
          <div className="checkboxes">
            <div className="checkbox-wrapper">
              <input
                type="checkbox"
                checked={formik?.values?.membershipStatus?.length === 6}
                onChange={handleCheckAll}
              />
              <label className="label">{t('All Status')}</label>
            </div>

            {Object.keys(membershipCardIds).map((card) => (
              <div key={card} className="checkbox-wrapper">
                <input
                  type="checkbox"
                  checked={formik?.values?.membershipStatus?.includes(membershipCardIds[card])}
                  onChange={(e) => handleCheckbox(e, card)}
                />
                <label className="label">{card}</label>
              </div>
            ))}
          </div>
          <div className="bottom-wrapper">
            <IonNote slot="error">
              {formik.touched.membershipStatus &&
                formik.errors.membershipStatus &&
                t(formik.errors.membershipStatus)}
            </IonNote>
          </div>
        </div>
        <DateInput
          name="publishDate"
          label="Publish Date"
          value={isoToDateObject(formik.values.publishDate)}
          onChange={handleDateChange}
          error={formik.touched.publishDate && formik.errors.publishDate}
          maxDate={formik.values.closeDate ? new Date(formik.values.closeDate) : null}
          required
          timeFilter={(e: any) => timeFilter(e, 'publishDate')}
          minTime={
            new Date(formik.values.closeDate).getDate() ===
              new Date(formik.values.publishDate).getDate() &&
            new Date(formik.values.closeDate).getMonth() ===
              new Date(formik.values.publishDate).getMonth()
              ? new Date(formik.values.closeDate).setHours(0, 0, 0, 0)
              : new Date().setHours(0, 0, 0, 0)
          }
          maxTime={
            new Date(formik.values.closeDate).getDate() ===
              new Date(formik.values.publishDate).getDate() &&
            new Date(formik.values.closeDate).getMonth() ===
              new Date(formik.values.publishDate).getMonth()
              ? new Date(formik.values.closeDate)
              : new Date().setHours(23, 55, 0, 0)
          }
        />
        <div className="note">
          <IonLabel>
            {t('You won’t be able to edit vote contents after vote is published')}
          </IonLabel>
        </div>
        <DateInput
          name="closeDate"
          label="Voting Deadline"
          value={isoToDateObject(formik.values.closeDate)}
          onChange={handleDateChange}
          error={formik.touched.closeDate && formik.errors.closeDate}
          minDate={formik.values.publishDate ? new Date(formik.values.publishDate) : null}
          required
          timeFilter={(e: any) => timeFilter(e, 'closeDate')}
          minTime={
            new Date(formik.values.closeDate).getDate() ===
              new Date(formik.values.publishDate).getDate() &&
            new Date(formik.values.closeDate).getMonth() ===
              new Date(formik.values.publishDate).getMonth()
              ? new Date(formik.values.publishDate)
              : new Date(0, 0, 0, 0)
          }
          maxTime={
            new Date(formik.values.publishDate).getDate() ===
              new Date(formik.values.closeDate).getDate() &&
            new Date(formik.values.publishDate).getMonth() ===
              new Date(formik.values.closeDate).getMonth()
              ? new Date(formik.values.publishDate).setHours(23, 55, 0, 0)
              : new Date().setHours(23, 55, 0, 0)
          }
        />
        <div className="button-wrapper">
          <Button size="large" className="submit-button" disabled={loading} type="submit">
            Confirm
          </Button>
        </div>
      </form>
    </Wrapper>
  );
};

export { VoteManagementForm };
