import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';

import { useToast } from '../hooks/useToast';
import { editTeamUser, fetchSingleTeamUserData } from '../services';
import { EditAdminUserTemplate } from '../components/organisms/CommonTemplate/UserManagement/EditAdminUserTemplate';

const EditTeamUser = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const id = pathname.split('/')[3];

  const [showToast, setShowToast] = useToast(false);
  const [errMsg, setErrMsg] = useState<string>('Something went wrong');

  const { data, isFetching } = useQuery(['singleUserData', id], fetchSingleTeamUserData, {
    enabled: !!id,
    select: (response) => {
      return response?.data;
    },
  });

  const { mutate, isLoading: isMutating } = useMutation(editTeamUser, {
    onSuccess: () => {
      history.push('/user-management');
    },
    onError: (e: any) => {
      setErrMsg(e?.response?.data?.message || 'Something went wrong');
      setShowToast(true);
    },
  });

  return (
    <EditAdminUserTemplate
      data={data}
      id={id}
      showToast={showToast}
      setShowToast={setShowToast}
      errMsg={errMsg}
      isFetching={isFetching}
      isMutating={isMutating}
      mutate={mutate}
    />
  );
};

export default EditTeamUser;
