import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router';

import { useToast } from '../hooks/useToast';
import { editTeamNotification, fetchSingleNotificationTeam } from '../services';
import { EditAdminNotificationTemplate } from '../components/organisms/CommonTemplate/Notification/EditAdminNotificationTemplate';

const TeamEditNotification = () => {
  const history = useHistory();
  const id = useParams<{ id: string;slug:string }>();
  const { search, pathname } = useLocation();
  const [showToast, setShowToast] = useToast(false);

  const { mutate, isLoading: isMutating } = useMutation(editTeamNotification, {
    onSuccess: async () => {
      history.push({
        pathname: `/${pathname.split('/')[1]}/notification`,
        search: `?list=${search?.split('?list=')[1] || 'beforePublishing'}`,
      });
    },
    onError: (e: any) => {
      setShowToast(true);
    },
  });

  const { data, isLoading, isFetching } = useQuery(
    ['fetchSingleNotification', id.slug],
    fetchSingleNotificationTeam,
    {
      enabled: !!id.slug,
      select: (response) => {
        return response?.data;
      },
    }
  );

  return (
    <EditAdminNotificationTemplate
      data={data}
      showToast={showToast}
      setShowToast={setShowToast}
      isLoading={isLoading}
      isFetching={isFetching}
      isMutating={isMutating}
      mutate={mutate}
    />
  );
};

export default TeamEditNotification;
