import * as yup from 'yup';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Header, Loader, Toast, VoteManagementForm } from '../../../../components';
import { getImageSelectionsStringified, getImageOptions, authContext } from '../../../../utils';

const EditAdminVoteTemplate = ({
  showToast,
  setShowToast,
  isLoading,
  isFetching,
  isMutating,
  mutate,
  data,
  errMsg,
}: any) => {
  const { user } = authContext();
  const [teamId, setTeamId] = useState<string>('');

  const [initialValues, setInitialValues] = useState<any>({
    id: '',
    title: '',
    content: '',
    selectionType: '',
    selections: '{"0":"","1":""}',
    images: [],
    status: false,
    showResult: false,
    publishDate: '',
    closeDate: '',
    membershipStatus: [],
  });

  const validationSchema = yup.object().shape({
    title: yup.string().required('Please enter'),
    content: yup.string().required('Please enter'),
    selectionType: yup.string().required('Please enter'),
    selections: yup.object().test({
      test: (obj, context) => {
        if (
          context?.parent['selectionType'] === 'SingleSelect' ||
          context?.parent['selectionType'] === 'MultipleSelect'
        ) {
          for (const key in obj) {
            if (!obj[key]) {
              return false;
            }
          }
          return true;
        } else return true;
      },
      message: 'Please enter',
    }),
    images: yup.array().when('selectionType', {
      is: 'ImageSelect',
      then: yup.array().min(1, 'Please enter'),
    }),
    status: yup.boolean().required('Please enter'),
    showResult: yup.boolean().required('Please enter').typeError('Please enter'),
    publishDate: yup.string().nullable().required('Please enter'),
    closeDate: yup.string().nullable().required('Please enter'),
    membershipStatus: yup.array().required('Please select').min(1, 'Please select'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mutate({
        teamId: teamId ? teamId : user?.teamId,
        data: {
          id: values?.id,
          title: values?.title,
          content: values?.content,
          selectionType: values?.selectionType,
          selections: getSelections(),
          status: values?.status,
          showResult: values?.showResult,
          publishDate: values?.publishDate,
          closeDate: values?.closeDate,
          target: {
            StatusIds: values?.membershipStatus,
          },
        },
      });
    },
  });

  useEffect(() => {
    if (data) {
      setInitialValues({
        ...data,
        images: data?.selectionType === 'ImageSelect' ? getImageOptions(data?.selections) : [],
        selections: data?.selectionType === 'ImageSelect' ? '{"0":"","1":""}' : data?.selections,
        membershipStatus: data?.target ? JSON.parse(data?.target).StatusIds : [],
      });
    }
  }, [data]);

  const getSelections = (): any => {
    if (
      formik?.values?.selectionType === 'SingleSelect' ||
      formik?.values?.selectionType === 'MultipleSelect'
    ) {
      return formik?.values?.selections ? JSON.parse(formik?.values?.selections) : {};
    } else {
      return formik?.values?.images
        ? JSON.parse(getImageSelectionsStringified(formik.values.images))
        : {};
    }
  };

  useEffect(() => {
    if (location.pathname.split('/')[1] !== 'vote-management') {
      setTeamId(location.pathname.split('/')[1]);
    }
  }, [location.pathname]);

  return (
    <div>
      <Header title="Edit Vote List" backButton />
      <Toast show={showToast} setShow={setShowToast} message={errMsg} />
      <div className="body-border">
        {isLoading || isFetching || formik?.values === undefined ? (
          <Loader hasWrapper />
        ) : (
          <VoteManagementForm formik={formik} loading={isMutating} />
        )}
      </div>
    </div>
  );
};

export { EditAdminVoteTemplate };
