import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { IonLabel, IonNote, IonText } from '@ionic/react';

import { teamListContext } from '../../../utils/TeamContext';
import { TextField, Button, RadioButton, Loader } from '../../../components';

interface Props {
  formik: any;
  isMutating?: boolean;
  type: 'add' | 'edit';
}

const Form = styled.form`
  .textfield {
    margin-bottom: 20px;
    ion-radio {
      --color-checked: #b80629;
    }
  }
  .required {
    color: #b80629;
    margin-left: 3px;
  }
  .info {
    color: #b80629;
    margin-bottom: 0px;
  }
  .message {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .two-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .button-wrapper {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    ion-button {
      width: 245px;
    }
  }

  .default-input {
    font-size: 13px;
    display: block;
    width: 98%;
    border: 1px solid #d9d9d9 !important;
    border-radius: 5px;
    height: 40px;
    margin: 10px 0px 2px;
    padding: 0 10px;
    outline: none;

    @media (min-width: 1550px) {
      width: 98.5%;
    }

    @media (min-width: 1850px) {
      width: 98.8%;
    }
  }

  ion-note {
    color: red;
    padding-top: 0;
    font-weight: 100;
  }

  .bottom-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .text-right {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    text-align: right;
    ion-text {
      margin-right: 0px;
      font-size: 12px;
    }
  }
  .disabled {
    input {
      background-color: rgba(235, 233, 233,0.5);
      color: rgba(0,0,0,0.4)
    }
`;

const SelectTeamLoading = styled.div`
  display: flex;
  flex-direction: column;

  .selectTeamLabel {
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

const SuperAdminUserManagementForm: React.FC<Props> = ({ formik, isMutating, type }) => {
  const { t } = useTranslation();
  const { teamList, teamListLoading } = teamListContext();
  const [teamOptions, setTeamOptions] = useState([]);

  const handleChange = (e: any) => {
    const pattern = /^[a-zA-Z0-9!@#$%^&*)(+=._-~`"/"?>,<'";:]*$/;
    const value = e.target.value;
    if (value === '' || pattern.test(value)) {
      formik.setFieldValue('username', value);
    }
  };

  useEffect(() => {
    if (teamList) {
      let options = teamList?.teams?.map((team: any) => {
        return { label: team?.teamName, value: team?.id };
      });
      setTeamOptions(options);
    }
  }, [teamList]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="message">
        <IonLabel>
          {t('Username')}

          {type !== 'edit' && (
            <>
              <span className="required">*</span>
            </>
          )}
        </IonLabel>
      </div>

      <div className={type === 'edit' ? 'disabled textfield' : 'textfield'}>
        <input
          maxLength={50}
          name="username"
          disabled={type === 'edit' ? true : false}
          className="default-input"
          value={formik.values.username}
          onChange={type === 'edit' ? () => {} : handleChange}
        />
        <div className="bottom-wrapper">
          <IonNote slot="error">
            {formik.touched.username && formik.errors.username && t(formik.errors.username)}
          </IonNote>
          <div className="text-right">
            <IonText>{`${formik.values.username?.toString().length}/${50}`}</IonText>
          </div>
        </div>
      </div>

      <div className="two-columns">
        <TextField
          count
          required
          maxlength={20}
          name="name_sei"
          label="Name Sei"
          className="textfield"
          value={formik.values.name_sei}
          onChange={formik.handleChange}
          error={formik.touched.name_sei && formik.errors.name_sei}
        />
        <TextField
          count
          required
          maxlength={20}
          name="name_mei"
          label="Name Mei"
          className="textfield"
          value={formik.values.name_mei}
          onChange={formik.handleChange}
          error={formik.touched.name_mei && formik.errors.name_mei}
        />
      </div>
      <div className="two-columns">
        <TextField
          count
          required
          maxlength={20}
          name="furigana_sei"
          label="Furigana Sei"
          className="textfield"
          value={formik.values.furigana_sei}
          onChange={formik.handleChange}
          error={formik.touched.furigana_sei && formik.errors.furigana_sei}
        />
        <TextField
          count
          required
          maxlength={20}
          name="furigana_mei"
          label="Furigana Mei"
          className="textfield"
          value={formik.values.furigana_mei}
          onChange={formik.handleChange}
          error={formik.touched.furigana_mei && formik.errors.furigana_mei}
        />
      </div>
      <div className="two-columns">
        <TextField
          count
          required
          name="email"
          label="Email"
          className="textfield"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && formik.errors.email}
        />
        <TextField
          count
          required
          maxlength={15}
          name="phone"
          label="Phone"
          className="textfield"
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={formik.touched.phone && formik.errors.phone}
        />
      </div>

      <TextField
        count
        required
        name="department"
        label="Department"
        className="textfield"
        value={formik.values.department}
        onChange={formik.handleChange}
        error={formik.touched.department && formik.errors.department}
      />

      {type === 'edit' && (
        <TextField
          passwordInfo
          name="password"
          label="Password"
          textType="password"
          className="textfield"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && formik.errors.password}
        />
      )}

      {/* <div className="two-columns"> */}
      <div>
        {/* HIDE FOR NOW */}
        {/* <RadioButton
          name="adminType"
          label="Admin Type"
          required
          // disabled
          options={[
            { value: 'faniqueAdmin', label: 'Fanique Admin' },
            { value: 'teamAdmin', label: 'Team Admin' },
          ]}
          onChange={formik?.handleChange}
          value={formik.values.adminType}
          error={formik.touched.adminType && formik.errors.adminType}
        /> */}
        {teamListLoading ? (
          <SelectTeamLoading>
            <IonLabel className="selectTeamLabel">
              チーム選択
              <span className="required">*</span>
            </IonLabel>
            <Loader />
          </SelectTeamLoading>
        ) : (
          // HIDE FOR NOW
          // <TextField
          //   required={formik?.values?.adminType === 'faniqueAdmin' ? false : true}
          //   disabled={formik?.values?.adminType === 'faniqueAdmin'}
          //   // disabled
          //   name="teamId"
          //   label="Select Teams"
          //   type="select"
          //   className={`textfield ${formik?.values?.adminType === 'faniqueAdmin' && 'disabled'}`}
          //   onChange={(e: any) => {
          //     formik.setFieldValue('teamId', e?.value);
          //   }}
          //   /* Until further API update */
          //   value={formik?.values?.adminType === 'faniqueAdmin' ? '' : formik?.values?.teamId}
          //   error={formik.touched.teamId && formik.errors.teamId}
          //   options={teamOptions}
          // />
          <TextField
            required={true}
            name="teamId"
            label="Select Teams"
            type="select"
            className="textfield"
            onChange={(e: any) => {
              formik.setFieldValue('teamId', e?.value);
            }}
            value={formik?.values?.teamId}
            error={formik.touched.teamId && formik.errors.teamId}
            options={teamOptions}
          />
        )}
      </div>

      <div className="button-wrapper">
        <Button size="large" type="submit" disabled={isMutating}>
          Save
        </Button>
      </div>
    </Form>
  );
};

export { SuperAdminUserManagementForm };
