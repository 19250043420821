import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';

import { queryClient } from '..';
import { MembershipCardTemplate } from '../components';
import { editMembershipCard, editMembershipSalesPeriod, fetchMembershipCards } from '../services';

const AdminMembershipCard = () => {
  const { id } = useParams<{ id: string }>();

  const [updatingCardId, setUpdatingCardId] = useState<string | undefined>('');
  const { data, isLoading, isFetching } = useQuery(
    ['fetchMembershipCards', id],
    fetchMembershipCards,
    {
      cacheTime: 0,
      select: (response) => {
        // sorting array for specific order
        response?.data?.statusMasters?.sort((a: any, b: any) => a?.levelFrom - b?.levelFrom);
        return response?.data;
      },
    }
  );
  const { mutate, isLoading: isMutating } = useMutation(editMembershipCard, {
    onSuccess: () => {
      setUpdatingCardId('');
      setTimeout(() => queryClient.invalidateQueries('fetchMembershipCards'), 1000);
    },
  });
  const { mutate: mutateSalesPeriod, isLoading: isMutatingSalesPeriod } = useMutation(
    editMembershipSalesPeriod,
    {
      onSuccess: () => {
        setTimeout(() => queryClient.invalidateQueries('fetchMembershipCards'), 1000);
        setDateRange(['', '']);
      },
    }
  );

  // For Date Changer
  const [dateRange, setDateRange] = useState<[string | Date, string | Date]>(['', '']);

  return (
    <MembershipCardTemplate
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      isMutatingSalesPeriod={isMutatingSalesPeriod}
      mutate={mutate}
      isMutating={isMutating}
      mutateSalesPeriod={mutateSalesPeriod}
      updatingCardId={updatingCardId}
      setUpdatingCardId={setUpdatingCardId}
      dateRange={dateRange}
      setDateRange={setDateRange}
      teamId={id}
    />
  );
};

export default AdminMembershipCard;
