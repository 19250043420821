import React, { useEffect } from 'react';
import styled from 'styled-components';
import { closeOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { IonModal, IonIcon } from '@ionic/react';

import { RadioButton } from '../../atoms';
import { useMutation } from 'react-query';
import { disableTeamUser, enableTeamUser } from '../../../services';
import { useParams } from 'react-router';

interface Props {
  isOpen: boolean;
  onCloseModal: () => void;
  formik?: any;
}

const IonModalComponent = styled(IonModal)`
  --width: 573px;
  --height: 188px;
  .header {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #7c7c7c;

    ion-icon {
      font-size: 20px;
      cursor: pointer;
    }

    p {
      font-weight: 500;
    }
  }

  .bottom-wrapper {
    padding: 0 20px;
    font-size: 14px;

    ion-radio {
      --color-checked: #b80629;
    }
  }
`;

const AccountStatusModal: React.FC<Props> = ({ isOpen, onCloseModal, formik }) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id?: string }>();

  const { mutate: disable, isLoading: isDisabling } = useMutation(disableTeamUser, {
    onSuccess: () => onCloseModal(),
  });
  const { mutate: enable, isLoading: isEnabling } = useMutation(enableTeamUser, {
    onSuccess: () => onCloseModal(),
  });

  const handleChange = (e: any) => {
    formik?.handleChange(e);
    formik?.setFieldTouched('cognitoAccountStatus', true);
  };

  useEffect(() => {
    if (!formik?.isSubmitting) {
      if (formik?.touched?.cognitoAccountStatus && formik?.values?.cognitoAccountStatus) {
        enable(id);
      } else if (formik?.touched?.cognitoAccountStatus && !formik?.values?.cognitoAccountStatus) {
        disable(id);
      }
    }
  }, [
    formik?.isSubmitting,
    formik?.touched?.cognitoAccountStatus,
    formik?.values?.cognitoAccountStatus,
  ]);

  return (
    <IonModalComponent isOpen={isOpen} onDidDismiss={onCloseModal}>
      <div className="header">
        <p>{t('Change Account Status')}</p>
        <IonIcon icon={closeOutline} onClick={onCloseModal} />
      </div>

      <div className="bottom-wrapper">
        <p>{t('Account Status')}</p>

        <RadioButton
          name="cognitoAccountStatus"
          required
          options={[
            { value: true, label: 'Active' },
            { value: false, label: 'Suspended' },
          ]}
          onChange={handleChange}
          value={formik?.values?.cognitoAccountStatus}
          className="radio-btn"
          disabled={isDisabling || isEnabling}
        />
      </div>
    </IonModalComponent>
  );
};

export { AccountStatusModal };
